<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>
          <div>
            <!-- Platform Info: Input Fields -->

            <validation-observer ref="platformValidation">
              <b-form @submit.prevent="validationForm">
                <b-row>
                  <!-- Field: Name -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Nom"
                      label-for="name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required|max:100|min:3"
                      >
                        <b-form-input
                          id="name"
                          v-model="platform.name"
                          placeholder="ccup"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['name']"
                          class="text-danger"
                        >{{ server_errors['name'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Subdomain -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Sous-domaine"
                      label-for="subdomain"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="subdomain"
                        rules="required|max:100|min:3"
                      >
                        <b-input-group append=".ccup.io">
                          <b-form-input
                            id="subdomain"
                            v-model="platform.subdomain"
                            placeholder="ccup"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['subdomain']"
                          class="text-danger"
                        >{{ server_errors['subdomain'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Slot -->
                  <b-col
                    cols="10"
                    md="4"
                  >
                    <b-form-group
                      label="Slot"
                      label-for="slot"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="slot"
                        rules="required|max_value:999999|min_value:1"
                      >
                        <b-form-input
                          id="slot"
                          v-model="platform.slot"
                          type="number"
                          placeholder="100"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['slot']"
                          class="text-danger"
                        >{{ server_errors['slot'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: messaging -->
                  <b-col
                    cols="2"
                    md="2"
                  >
                    <b-form-group
                      label="Messagerie"
                      label-for="messaging"
                    >
                      <b-form-checkbox
                        id="messaging"
                        v-model="platform.is_messaging_enable"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label">{{ platform.is_messaging_enable ? 'oui' : 'non' }}</span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Competition -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Competitions"
                      label-for="Competitions"
                    >
                      <v-select
                        v-model="competition"
                        :dir="'ltr'"
                        :options="competitionsOptions"
                        multiple
                        :clearable="true"
                        input-id="Competitions"
                      />
                      <small
                        v-if="server_errors && server_errors['competition_id']"
                        class="text-danger"
                      >{{ server_errors['competition_id'][0] }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- Field: languges -->
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Français"
                      label-for="lang_fr"
                    >
                      <b-form-checkbox
                        id="lang_fr"
                        v-model="lang_fr"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-fr.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Anglais"
                      label-for="lang_en"
                    >
                      <b-form-checkbox
                        id="lang_en"
                        v-model="lang_en"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-en.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Allemand"
                      label-for="lang_de"
                    >
                      <b-form-checkbox
                        id="lang_de"
                        v-model="lang_de"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-de.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Italien"
                      label-for="lang_it"
                    >
                      <b-form-checkbox
                        id="lang_it"
                        v-model="lang_it"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-it.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Espagnol"
                      label-for="lang_es"
                    >
                      <b-form-checkbox
                        id="lang_es"
                        v-model="lang_es"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-es.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Action Buttons -->
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="formLoading"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  <b-spinner
                    v-show="formLoading"
                    small
                  />
                  Enregistrer
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  max, max_value, min, min_value, required,
} from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BForm, BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BMedia,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'PlatformsCreate',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    vSelect,
    BSpinner,
    BInputGroup,
    BFormCheckbox,
  },
  data() {
    return {
      loading: true,
      formLoading: false,
      competitionsOptions: [],
      competition: [],
      required,
      max,
      min,
      max_value,
      min_value,
      platform: {
        name: '',
        subdomain: '',
        slot: 1,
        competition_id: null,
        is_messaging_enable: true,
      },
      lang_fr: true,
      lang_en: true,
      lang_de: false,
      lang_it: false,
      lang_es: false,
      platformReference: null,
      server_errors: null,
    }
  },
  mounted() {
    this.fetchCompetitions()
  },
  methods: {
    async fetchCompetitions() {
      try {
        this.loading = true
        const competitions = await axiosIns.get('/competitions/minimal')
        competitions.data.forEach(el => {
          this.competitionsOptions.push({
            label: el.name,
            value: el.uuid,
          })
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async submitForm() {
      try {
        this.formLoading = true
        this.server_errors = null
        const form = new FormData()
        form.append('name', this.platform.name)
        form.append('subdomain', this.platform.subdomain ? this.platform.subdomain.toLowerCase() : '')
        form.append('slot', this.platform.slot)
        form.append('is_messaging_enable', this.platform.is_messaging_enable)
        this.competition.forEach(competition => {
          form.append('competition_id[]', competition.value)
        })
        if (this.lang_fr) {
          form.append('languages_code[]', 'fr')
        }
        if (this.lang_en) {
          form.append('languages_code[]', 'en')
        }
        if (this.lang_de) {
          form.append('languages_code[]', 'de')
        }
        if (this.lang_it) {
          form.append('languages_code[]', 'it')
        }
        if (this.lang_es) {
          form.append('languages_code[]', 'es')
        }
        const platform = await axiosIns.post('platforms', form)
        await this.$router.push({
          path: `/platforms/${platform.data.data.uuid}`,
        })
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La plateforme à bien été créée',
            },
          },
          { timeout: 5000 },
        )
        this.formLoading = false
      } catch (err) {
        this.formLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    validationForm() {
      this.$refs.platformValidation.validate().then(success => {
        if (success) {
          this.submitForm()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
